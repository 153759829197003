@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('./_assets.scss');

// * {
//     box-sizing: border-box;
// }

$secondary_color: #111123;
$border-color: rgba(200, 200, 200, 0.644);

//Dark mode styling here...
.darkmode {
    background-color: #141519;

    p,
    h1,
    h2,
    h3 {
        color: white !important;
    }

    .about_section {
        .about {
            .poka_icon {
                img {
                    filter: invert(100%);
                }
            }
        }
    }

    .portfolio_section {
        .portfolio_container {

            .cards {
                .details {
                    .long_arrow {
                        img {
                            filter: invert(100%);
                        }
                    }
                }


            }

        }

    }

    .logo,
    .link_icon {
        img {
            filter: invert(100%);
        }

    }

    .mobile_caption {
        h5 {
            filter: invert(100%);
        }
    }


    .pause_button {
        filter: invert(100%);
    }

    .layout{
        .parent{
            .child{
                background-color: #141519;
                .play_buttons{
                    img{
                        filter: invert(1);
                    }
                }
                
            }
        }
        
    }

    .gallery_container{
        background-color: #141519;
    }

    .glow{
        img{
            filter: invert(1);
        }
    }

    .gradient_background{
        .gradient{
            background-image: linear-gradient(to top,#1e2026, black) !important;
        }
    }

}




body {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    margin: 0;
    padding: 0;
    overflow: hidden;

    [data-barba="container"] {
        position: absolute;
    }

    .layout {
        width: 100%;
        // height: max(4280px,30vh);
        // border: red 1px solid;
        position: absolute;
        top: 0;
        z-index: 1;
        pointer-events: none;
        display: none;
        opacity: 0;
        .parent{
            width: 100%;
            height: 100dvh;
            //transition: .5s ease-out;
            position: sticky;
            top: 0;

            .child {
                width: 100%;
                height: 150px;
                border-top: 1px solid gray;
                border-bottom: 1px solid rgb(217, 217, 217);
                background-color: white;
                position: absolute;
                bottom: 0;
                display: grid;
                place-items: center;
                pointer-events: auto;
    
                .container {
                    width: 95%;
                    margin: 0 auto;
                    
    
                    .flexbox {
                        display: flex;
    
                        .flex1 {
                            display: flex;
                            gap: 10px;
                            width: 90%;
    
                            .flex {
                                display: grid;
                                place-items: center;
                                &:nth-child(1) {
                                    width: 50%;
    
                                    .current_image {
                                        width: 100%;
    
                                        img {
                                            width: 100%;
                                        }
                                    }
    
    
                                }
    
                                &:nth-child(2){
                                    .content{
                                        width: 90%;
                                        h5{
                                            margin: 0;
                                            font-weight: 500;
                                            
                                        }
        
                                        .mobile_anim_title{
                                            h3{
                                                margin: 0;
                                                margin-top: 10px;
                                                font-weight: 700;
                                                font-size: .9rem;
                                                line-height: 1.4;
                                            }
                                        }
    
                                        p{
                                            font-size: .7rem;
                                            font-weight: 400;
                                            color: rgb(90, 90, 90);
                                            line-height: 1.4;
                                        }
                                    }
                                    
                                }
                            }
                        }
    
                        .flex2 {
                            width: 10%;
                            display: grid;
                            place-items: center;
    
                            .play_buttons {
                                width: 100%;
    
                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }

        
    }

    nav {
        position: fixed;
        height: fit-content;
        top: 0;
        //transition: top 2.5s ease-out;
        width: 100%;
        z-index: 100;
        background-color: #14132185;
        backdrop-filter: blur(10px);
        transition: top 1s ease-out;
        //pointer-events: none;

        ul {
            display: flex;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            border-bottom: 1px solid rgb(77, 77, 77);


            li {
                list-style-type: none;

                &:nth-child(1) {
                    padding: 25px 50px;
                    border-right: 1px solid rgb(100, 100, 100);
                    position: relative;
                    cursor: pointer;

                    .menu {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        pointer-events: auto;
                        //opacity: 0;

                        .bar {
                            width: 40px;
                            height: 1px;
                            background-color: white;
                            margin: 10px 0;
                        }
                    }



                }

                &:nth-child(2) {
                    padding: 20px 0px;
                    width: 110px;
                    display: grid;
                    place-items: center;


                    img {
                        width: 100%;

                    }
                }

                &:nth-child(3) {
                    display: flex;
                    border-left: 1px solid gray;

                    div {
                        cursor: pointer;

                        &:nth-child(1) {
                            width: 28px;
                            padding: 25px 30px;

                            img {
                                width: 100%;
                                filter: invert(100%);
                            }
                        }

                        &:nth-child(2) {
                            padding: 25px 40px;
                            padding-left: 0;
                            display: grid;
                            place-items: center;

                            p {
                                margin: 0;
                                color: white;
                            }
                        }
                    }
                }
            }


        }

        .menu_container {
            color: white;
            height: 100svh;
            display: none;

            .container {
                width: 90%;
                margin: auto;
                height: inherit;

                .flexbox {
                    display: flex;
                    height: inherit;
                    gap: 50px;

                    .flex1 {
                        width: 80%;

                        div {
                            border-top: 1px solid rgba(223, 223, 223, 0.509);
                            width: 0;
                            overflow: hidden;
                            position: relative;

                            &:nth-child(1) {
                                border-top: none;
                                width: 100%;
                                display: flex;

                            }

                            h1 {
                                font-size: clamp(1rem, 4vw, 4.5rem);
                                font-weight: 100;
                                margin: 35px 0;
                                position: relative;
                                top: 200px;
                                pointer-events: auto;
                                cursor: pointer;

                                a {
                                    color: white;
                                    text-decoration: none;
                                }

                                &:nth-child(even) {
                                    position: absolute;
                                    top: 200px;
                                }

                            }

                            &:nth-child(1) {
                                margin-top: 100px;
                            }

                            &:nth-child(2) {
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                div {
                                    margin-top: auto;
                                }

                                img {
                                    width: 10%;
                                    height: 10%;
                                    object-position: center;
                                    cursor: pointer;
                                }

                                .toggle_arrow {
                                    transform: scale(-1);
                                }
                            }

                            &:nth-child(4) {
                                padding-top: 150px;
                                display: flex;
                                justify-content: space-between;
                                border-bottom: 1px solid rgba(223, 223, 223, 0.255);

                                p {
                                    font-weight: 100;
                                    font-size: .9rem;
                                    position: relative;
                                    top: 200px;
                                }
                            }
                        }
                    }

                    .flex2 {
                        width: 20%;
                        border-left: 1px solid gray;
                        height: inherit;
                        display: grid;
                        place-items: center;

                        .content {
                            a {
                                color: white;

                                .featured_gallery {
                                    width: 100%;
                                    position: relative;
                                    margin-left: 20px;

                                    img {
                                        width: 100%;
                                    }

                                    .action_button {
                                        display: flex;
                                        position: absolute;
                                        bottom: 2%;
                                        left: 15%;
                                        transform: translate(-15%, -2%);
                                        align-items: center;

                                        span {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }


                            .nav_location {
                                margin-top: 40px;

                                ol {
                                    list-style-type: none;

                                    li {
                                        font-weight: 100;
                                        padding-top: 5px;
                                        font-size: .7rem;

                                        &:nth-child(1) {
                                            font-size: 1rem;
                                            font-weight: 300;

                                        }
                                    }


                                }
                            }
                        }

                    }
                }
            }
        }

        .active {
            display: block;
            // opacity: 1;
        }
    }

    .add_nav {
        top: -100%;
        transition: 2.5s ease-out;

    }

    //Iframe container styling begins here...
    .iframe_container {
        width: 80%;
        height: 100svh;
        position: fixed;
        top: 0;
        background-color: black;
        display: grid;
        place-items: center;
        transition: .3s;
        display: none;
        z-index: 102;

        .close {
            position: absolute;
            top: 12%;
            right: 2%;
            transform: translate(-2%, -12%);
            z-index: 9999;
            cursor: pointer;
            background-color: black;
            color: white;
            padding: 10px 20px;
        }

        iframe {
            width: 100%;
            height: 100%;

        }
    }

    //Page Transitions styling here...
    .page_transition {
        position: fixed;
        top: 0;
        width: 100%;
        height: 100vh;
        display: flex;
        z-index: 1000;
        overflow: hidden;
        pointer-events: none;

        &>div {
            display: none;
            width: 25%;
            height: 100svh;
            background-color: white;
            transform: translateY(100%);
            background-color: rgb(237, 237, 237);
            align-items: center;
            justify-content: center;

            &:nth-child(2) {
                img {
                    width: 30px;
                }
            }

            img {
                width: 25px;
                opacity: .3;
            }
        }
    }


    //Loading screen styling here...
    .loading_screen {
        width: 100%;
        height: 100dvh;
        position: absolute;
        top: 0;
        z-index: 9999;
        display: none;
        background-color: white;

        .loading_logo {
            width: inherit;
            height: inherit;
            display: grid;
            place-items: center;



            video {
                width: 85%;
                -webkit-mask-image: url(../images/loading-logo@2x.png);
                mask-image: url(../images/loading-logo@2x.png);
                -webkit-mask-size: 90%;
                mask-size: 90%;
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                mask-position: center;

            }
        }
    }

    //Gallery container styling begins here..
    .gallery_container {
        width: 80%;
        height: 100svh;
        position: fixed;
        top: 0;
        background-color: white;
        display: none;
        place-items: center;
        transition: .3s;
        z-index: 100;

        .close_gallery {
            position: absolute;
            top: 12%;
            right: 2%;
            transform: translate(-2%, -12%);
            z-index: 9999;
            cursor: pointer;
            background-color: black;
            color: white;
            padding: 10px 20px;
        }

        .arrow_holder {
            width: 90%;
            display: flex;
            justify-content: space-between;

            img {
                cursor: pointer;

                &:nth-child(2) {
                    transform: scale(-1);
                }
            }
        }

        .picture_holder {
            width: 70%;
            position: absolute;
            display: grid;
            place-items: center;
            top: 50%;
            transform: translateY(-50%);

            img {
                width: inherit;
                height: inherit;
                object-fit: contain;
            }
        }
    }


    section {
        .parent_container {


            // banner styling here...
            .banner {
                width: 100%;
                height: 100svh;
                top: 0;
                overflow: hidden;
                position: relative;

                .banner_trey {
                    width: 200%;
                    height: inherit;
                    overflow: hidden;
                    position: absolute;
                    left: 0;
                    display: flex;
                    transition: 1.5s cubic-bezier(0.075, 0.82, 0.165, 1);

                    .video_holder {
                        width: 100%;
                        height: inherit;

                        video {
                            width: 50%;
                            height: inherit;
                            object-fit: cover;
                            position: absolute;
                            top: 0;
                        }
                    }

                }



                .banner_content {
                    position: absolute;
                    color: white;
                    bottom: 0;
                    width: 95%;
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    text-align: center;
                    left: 50%;
                    transform: translateX(-50%);
                    margin-bottom: 50px;
                    border-top: 1px solid rgb(70, 70, 70);

                    .banner_mute {
                        cursor: pointer;
                    }


                    div {
                        &:nth-child(2) {
                            width: 450px;
                            line-height: 1.8;

                            h1 {
                                font-size: clamp(1rem, 2vw, 2rem);
                                font-family: 'gill';

                            }

                            p {
                                margin-top: 5px;
                                margin-bottom: 0;
                                font-size: .9rem;
                            }
                        }

                        &:nth-child(3) {
                            cursor: pointer;
                            transition: .3s;
                            text-decoration: underline;


                            &:hover {
                                letter-spacing: .5px;
                                transform: scale(1.05);
                                font-weight: 700;
                            }
                        }


                    }


                }




            }


            //Empowering Women Section Begins here
            .women_community {
                width: 100%;
                height: 650px;
                //background-color: red;
                display: flex;
                align-items: flex-end;

                .background {
                    width: inherit;
                    height: 100%;
                    background-image: linear-gradient(to top, #101621, #5F636A);
                    position: relative;

                    .woman_img {
                        width: 43%;
                        position: absolute;
                        bottom: 0;
                        left: 10%;
                        transform: translateX(-10%);

                        img {
                            width: 100%;
                        }
                    }

                    .content_container {
                        width: 70%;
                        margin: 0 auto;
                        height: inherit;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        .content {
                            width: 50%;
                            font-family: 'Poppins';

                            h1 {
                                font-size: clamp(1rem, 4vw, 3.5rem);
                                margin: 0;
                                font-weight: 500;
                                color: #FDE597;
                            }

                            h5 {
                                color: rgb(170, 170, 170);
                                font-weight: 300;
                            }

                            p {
                                line-height: 1.7;
                                color: white;
                                font-size: 1.1rem;
                                font-weight: 200;
                            }

                            .buttons_container {
                                margin-top: 50px;

                                button {
                                    padding: 15px 40px;
                                    font-size: 1.1rem;
                                    border-radius: 5px;
                                    background-color: transparent;
                                    border: 1px solid gray;
                                    color: black;
                                    background-color: white;
                                    cursor: pointer;
                                }

                                button:nth-child(2) {
                                    background-color: transparent;
                                    color: white;
                                    margin-left: 15px;
                                }
                            }
                        }
                    }
                }
            }

            //lastest project styling starts here...
            .main {

                margin-top: 10px;

                .latest_container {
                    margin-top: 100px;

                    .last_night {
                        position: relative;
                        width: 90%;
                        margin: 0 auto;


                        .flexbox {
                            display: flex;
                            gap: 70px;



                            .latest_project_details {
                                width: max(300px, 45%);
                                position: relative;

                                .latest_flex {
                                    display: flex;
                                    align-items: center;

                                    .svg {
                                        width: 70px;
                                        height: 100px;
                                        position: relative;

                                        svg {
                                            position: absolute;
                                            left: -20px;
                                            fill: transparent;
                                            stroke: red;

                                            transform: rotate(-90deg);
                                            stroke-dasharray: 251;
                                            transition: linear 10s;
                                            stroke-dashoffset: 251;
                                            transform: scale(.5);

                                        }

                                        .pause_button {
                                            position: absolute;
                                            width: 15px;
                                            top: 52%;
                                            left: 40%;
                                            transform: translate(-40%, -52%);
                                            cursor: pointer;


                                            img {
                                                width: 100%;

                                            }
                                        }
                                    }


                                }

                                h1 {
                                    color: $secondary_color;
                                    font-family: 'Poppins';
                                    font-size: clamp(1rem, 2vw, 1.4rem);
                                    opacity: 0;

                                }



                                .latest_description {
                                    container-type: inline-size;
                                    width: 100%;
                                    opacity: 0;
                                    padding-top: 100px;
                                    position: absolute;
                                    bottom: 10%;
                                    transform: translateY(-15%);

                                    overflow: hidden;

                                    .content_trey {
                                        display: flex;
                                        width: 200%;

                                        .content_description {

                                            width: 50%;
                                            display: flex;
                                            //gap: 20px;

                                            p {
                                                width: auto !important;
                                                font-size: clamp(.7rem, .8vw, 1rem);
                                            }

                                            &:nth-child(1) {
                                                display: flex;
                                                align-items: flex-end;
                                            }

                                            .mobile_caption {
                                                display: none;

                                                h1 {
                                                    color: black;
                                                    font-size: 2rem;
                                                    opacity: 1;
                                                }
                                            }


                                        }
                                    }

                                    p {
                                        width: max(400px, 50%);
                                        font-size: clamp(.3rem, 1vw, 1rem);
                                        line-height: 1.9;
                                        color: rgb(85, 85, 85);
                                        font-weight: 500;

                                    }

                                }

                                .visible {
                                    opacity: 1;
                                    padding-top: 0;
                                    transition: opacity .5s, padding-top .5s;
                                }



                            }


                            .poster1 {

                                width: 550px;
                                opacity: 0;
                                transition: opacity .5s;

                                overflow: hidden;
                                position: relative;

                                .flex2_trey {
                                    width: 200%;
                                    display: flex;

                                    img {
                                        width: 50%;

                                    }

                                    .new_project {
                                        width: 100%;

                                        video {
                                            width: 100%;
                                            height: auto;
                                        }

                                    }

                                }

                            }

                            .content {
                                width: 520px;
                                position: relative;


                                .last_night_caption {
                                    opacity: 0;
                                    padding-top: 50px;
                                    transition: opacity .5s, padding-top 1s ease-out;
                                    overflow: hidden;

                                    .last_night_caption_trey {
                                        display: flex;
                                        width: 200%;

                                        .captions {
                                            width: 100%;

                                            h1 {
                                                font-size: clamp(1rem, 4vw, 5.5rem);
                                            }

                                            p {
                                                font-weight: 500;
                                                font-size: clamp(.9rem, 1vw, 1.1rem);
                                            }
                                        }
                                    }

                                    h1 {
                                        width: 100%;
                                        font-family: gill;
                                        font-size: clamp(1rem, 6.3vw, 6.2rem);
                                        text-transform: uppercase;
                                        line-height: 1;
                                        margin: 0;
                                        background: -webkit-linear-gradient(rgb(255, 58, 18), rgb(180, 0, 0));
                                        -webkit-background-clip: text;
                                        -webkit-text-fill-color: transparent;
                                    }

                                    p {
                                        line-height: 1.6;
                                        color: rgb(54, 54, 54);
                                        font-size: .9rem;
                                        width: max(450px, 50%);
                                    }



                                }

                                .poster2 {
                                    width: max(150px, 40%);
                                    position: absolute;
                                    bottom: -30px;
                                    overflow: hidden;

                                    .poster2_trey {
                                        display: flex;
                                        align-items: center;
                                        width: 200%;

                                        .supporting_images {
                                            width: 50%;

                                            img {
                                                width: 90%;

                                            }
                                        }
                                    }




                                }

                            }

                        }


                    }

                    .latest_project {
                        position: relative;
                        top: -80px;
                        width: 100%;
                        z-index: -1;

                        .last_night_details {
                            display: flex;
                            justify-content: space-between;
                            border-bottom: solid 1px rgb(190, 190, 190);
                            border-top: solid 1px rgb(190, 190, 190);
                            width: inherit;

                            p {
                                padding: 55px 100px;
                                margin: 0;
                            }

                            p:nth-child(2) {
                                border-left: gray 1px solid;
                                text-decoration: underline;
                                font-weight: 500;
                                font-size: clamp(1rem, 1.5vw, 1.1rem);
                            }
                        }
                    }


                }
            }

            //Our works styling starts here...
            .portfolio_section {
                display: grid;



                .caption {
                    text-align: center;

                    h1 {
                        font-size: clamp(1rem, 11vw, 13rem);
                        color: $secondary_color;
                        text-transform: uppercase;
                        font-weight: 900;
                        font-family: 'gill';
                        letter-spacing: 5px;
                        margin: 0;

                        span {
                            opacity: 0;
                            position: relative;
                            top: 50px;
                        }
                    }
                }

                //Portfolio Container styling here...
                .portfolio_container {
                    width: 90%;
                    margin: 0 auto;
                    position: relative;
                    height: max(100em, 200dvh);
                    margin-top: 100px;

                    h1 {
                        font-size: clamp(1px, 2.5vw, 4.5rem);
                        width: 40%;
                        text-decoration: underline;
                        line-height: 1.5;
                        font-family: 'poppins';
                    }

                    .cards {
                        width: fit-content;
                        width: 30%;

                        .trigger {
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            top: 0;
                            z-index: 10;
                            cursor: pointer;


                        }

                        .cover {
                            padding-bottom: 30px;
                            overflow: hidden;
                            position: relative;

                            img {
                                width: 100%;
                                border: solid 1px rgb(93, 93, 93);
                            }

                            video {
                                position: absolute;
                                top: 0;
                                transition: .5s;

                                opacity: 0;
                                visibility: hidden;
                                display: none;
                            }


                        }

                        .details {
                            border-top: 1px solid rgb(200, 200, 200);
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding-top: 5px;

                            h3 {
                                text-decoration: underline;
                                color: $secondary_color;
                            }
                        }
                    }

                    a {
                        .cards {
                            position: absolute;
                            top: 0;
                            right: 3%;
                            transform: translateX(-3%);

                            width: 470px;
                            height: 370px;
                            margin-top: 50px;
                            transition: .5s;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }

                            a {
                                border: red 10px solid;
                                position: relative;

                            }

                            .cover,
                            .details {
                                transition: .5s;
                            }

                            video {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }


                        }
                    }

                    a:nth-child(3) {
                        .cards {
                            width: 450px;
                            position: absolute;
                            top: 23%;
                            left: 3%;
                            transform: translateY(-3%, -23%);

                            .cover,
                            .details {
                                transition: .5s;
                            }

                            .cover {
                                width: 450px;
                                height: 350px;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                            }

                            video {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }


                        }
                    }



                    .cards:nth-child(4) {
                        position: absolute;
                        right: 5%;
                        top: 60%;
                        transform: translate(-5%, -60%);
                        //display: flex;

                        .cover {
                            width: 470px;
                            height: 370px;
                            margin-top: 50px;
                            overflow: hidden;
                            transition: .5s;

                            img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border: solid 1px rgb(93, 93, 93);
                            }

                            video {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .details {
                            transition: .5s;
                        }



                        .flex1 {
                            margin-top: 180px;
                            border-top: 1px solid rgb(200, 200, 200);

                            p {
                                //width: 450px;
                                //padding-right: 100px;
                                padding-top: 30px;
                            }
                        }

                        p {

                            font-size: .9rem;
                            line-height: 2;
                            margin: 0;
                            transition: .5s;
                        }

                        .flex2 {
                            p {
                                //width: max(450px,40%);
                                color: rgb(77, 77, 77);
                            }
                        }
                    }

                    .cards:nth-child(5) {
                        position: absolute;
                        top: 65%;
                        left: 7%;
                        transform: translateY(-7%, -65%);
                        cursor: pointer;
                        width: 450px;
                        height: 350px;

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        .cover,
                        .details {
                            transition: .5s;
                        }
                    }

                }


            }

            // Reels section style starts here...
            .reels_section {
                width: 100%;
                background-color: $secondary_color;
                color: white;
                display: none;

                .reels_container {
                    width: 80%;
                    margin: 0 auto;
                    display: flex;
                    padding: 100px 0;
                    height: 80vh;
                    align-items: flex-end;

                    .flex1 {
                        width: 55%;
                        height: inherit;

                        .reel_details {
                            width: 70%;
                            height: inherit;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;


                            .reel_heading {
                                opacity: 0;

                                h1 {
                                    font-size: clamp(1rem, 3vw, 2.1rem);
                                    font-family: 'poppins';
                                }

                                p {
                                    font-size: .9rem;
                                }

                                .socials {
                                    display: flex;
                                    align-items: center;
                                    margin-top: 25px;

                                    .social1 {
                                        width: 10%;

                                        img {
                                            width: 100%;

                                        }
                                    }

                                    .social2,
                                    .social3 {
                                        width: 10%;
                                        padding-left: 10px;

                                        img {
                                            width: 100%;
                                            object-position: center;
                                        }
                                    }

                                    .social3 {
                                        width: 8%;

                                        img {
                                            width: 100%;
                                            object-position: center;
                                        }
                                    }
                                }
                            }

                            .details {
                                opacity: 0;

                                p {
                                    line-height: 1.9;
                                    font-size: .9rem;
                                }
                            }
                        }
                    }

                    .flex2 {
                        width: 35%;
                        height: 80vh;
                        overflow: hidden;
                        display: flex;
                        align-items: flex-end;
                        border: 1px white solid;
                        opacity: 0;

                        .video_reel {
                            width: 100%;
                            height: 100%;
                            //background-color: red;
                            overflow: hidden;

                            .video_trey {
                                height: 120vh;
                                transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

                                .overlay {
                                    display: none;
                                }

                                video {
                                    width: 100%;
                                    height: 80vh;
                                    object-fit: cover;
                                }

                            }
                        }

                    }

                    .flex3 {
                        display: flex;
                        align-items: flex-end;
                        padding-left: 100px;
                        opacity: 0;

                        .reel_buttons {
                            display: flex;
                            flex-direction: column;
                            height: 30vh;
                            justify-content: space-evenly;

                            .icon {
                                cursor: pointer;
                            }

                            .icon:nth-child(1) {
                                width: 40px;

                                img {
                                    width: 100%;
                                    transform: rotate(-90deg);
                                }
                            }

                            .icon:nth-child(2) {
                                width: 40px;

                                img {
                                    width: 100%;
                                    transform: rotate(90deg);
                                }
                            }

                            .icon:nth-child(3) {
                                width: 40px;

                                img {
                                    width: 100%;
                                }
                            }

                            .icon:nth-child(4) {
                                width: 40px;

                                img {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            //About Poka studios styling starts here
            .about_section {
                width: 100%;
                display: grid;
                place-items: center;
                padding: 130px 0px;
                //background-image: url(https://img.freepik.com/free-vector/light-grey-dots-background_78370-2583.jpg?t=st=1714661101~exp=1714664701~hmac=3a69dca357fc0eea4604a7cb77a48869f10b294e7d3748ca958d94e4a92400d4&w=1800);
                background-size: 300px;
                background-attachment: fixed;

                .about {
                    text-align: center;
                    width: 40%;
                    opacity: 0;
                    padding-top: 100px;
                    transition: opacity .5s, padding-top 1s ease-out;

                    h1 {
                        font-size: clamp(1rem, 6vw, 7rem);
                        margin: 0;
                        font-family: 'gill';
                    }

                    p {
                        font-size: clamp(1rem, 1.4vw, 1.3rem);
                        line-height: 1.5;
                    }

                    p:nth-child(4) {
                        text-decoration: underline;
                    }
                }
            }

            .line {
                width: 100%;
                border: 1px solid $border-color;
            }

            //Footer styling begins here...
            footer {
                .contact {
                    display: flex;
                    justify-content: space-between;

                    .flex {
                        width: 50%;
                        display: grid;

                        //padding: 100px 0;
                        display: flex;
                        align-items: center;
                        gap: 20px;



                        .contact_img {
                            border-right: gray 1px solid;
                            overflow: hidden;
                            width: 165px;
                            opacity: 0;

                            img {
                                transform: scale(1.06);

                                width: 100%;
                            }
                        }

                        .challenge_img {
                            width: 200px;
                            border-right: gray 1px solid;
                            overflow: hidden;
                            opacity: 0;

                            img {
                                transform: scale(1.03);

                                width: 100%;
                            }
                        }

                        .content {
                            display: flex;
                            justify-content: center;
                            flex-grow: 1;
                            width: fit-content;
                            opacity: 0;
                        }

                        h1 {
                            font-family: 'poppins';
                            font-size: clamp(1rem, 2vw, 1.5rem);
                            margin: 0;
                            line-height: 2;
                            text-decoration: underline;
                            cursor: pointer;
                            width: 90%;
                        }

                        .challenge {
                            width: 60%;
                        }

                        h3 {
                            margin: 0;
                        }
                    }

                    .line_2 {
                        border: 1px solid $border-color;
                    }

                }

                .footer {
                    padding: 100px 0;

                    .footer_content {
                        display: flex;
                        justify-content: space-around;



                        .location {
                            margin-top: 20px;

                            .location_container {
                                display: flex;
                                align-items: flex-end;

                                h2 {
                                    margin: 0;
                                    text-decoration: underline;

                                    
                                }

                                div{
                                    width: 15px;
                                    padding-left: 10px;
                                    img{
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        .social_media {
                            ul {
                                list-style: none;

                                li {
                                    margin: 20px 0;
                                    display: flex;
                                    align-items: flex-end;

                                    a {
                                        color: black;

                                        h2 {
                                            margin: 0;
                                            margin-right: 10px;
                                        }

                                        
                                    }

                                    div{
                                        width: 15px;
                                        img{
                                            width: 100%;
                                        }
                                    }

                                }

                            }
                        }
                    }
                }
            }

            //Our works styling begins here
            .work_section {
                width: 100%;
                border-bottom: 1px solid $border-color;
                position: relative;
                // background-color: wheat;

                .gradient_background {
                    position: absolute;
                    width: 80%;
                    //background-color: red;

                    .gradient {
                        width: 100%;
                        height: 100svh;
                        background: linear-gradient(to top, rgb(211, 211, 231), transparent);
                        position: sticky;
                        top: 0;
                    }
                }

                .works_flexbox {
                    display: flex;
                    height: 100%;

                    .works_container {
                        width: 80%;
                        height: 100%;
                        position: relative;
                        //background-color: rgb(233, 234, 239);





                        .works {
                            
                            //Vizualizers styling begins here
                            .vizualizer_container {
                                display: flex;
                                height: 110vh;
                                justify-content: space-around;
                                // background-color: red;
                                padding: 200px 0 0;

                                .vizualizer {
                                    width: 40%;
                                    position: relative;
                                    transition: .3s transform ease-out;
                                    transform: rotate3d(0);
                                    cursor: pointer;
                                    
                                    .viz_img_container{
                                        width: 100%;
                                        height: max(275px,38vh);
                                        img {
                                            width: 100%;
                                            border: 1px solid gray;
    
                                        }

                                        .glow{
                                            height: inherit;
                                        }
                                    }

                                    .gallery_img_container{
                                        width: 100%;
                                        

                                        img {
                                            width: 100%;
                                            border: 1px solid gray;
    

                                            .glow{
                                                height: inherit;
                                            }
                                        }
                                    }

                                    

                                    &:hover {
                                        transition: 0.15s;
                                    }


                                }

                                .vizualizer:nth-child(1) {
                                    cursor: pointer;

                                    .glow {
                                        position: absolute;
                                        width: 100%;
                                        cursor: pointer;
                                        top: 0;
                                        left: 0;
                                        background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
                                        display: grid;
                                        place-items: center;
                                       

                                        img {
                                            width: 30%;
                                            border: none;
                                            padding-top: 20px;
                                            opacity: 0;
                                            transform: scale(.9);
                                            transition: .5s;
                                            transition-delay: .15s;
                                        }

                                        &:hover img {
                                            transition-delay: .15s;
                                            opacity: 1;
                                            transform: scale(1);
                                            padding-top: 0;
                                        }


                                    }
                                }

                                .vizualizer:nth-child(2) {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: flex-end;
                                    height: 85%;

                                    .glow {
                                        position: absolute;
                                        width: 100%;
                                        // height: 50%;
                                        bottom: 0;
                                        left: 0;
                                        background-image: radial-gradient(circle at 50% -20%, #ffffff22, #0000000f);
                                        display: grid;
                                        place-items: center;

                                        img {
                                            width: 30%;
                                            border: none;
                                            padding-top: 20px;
                                            opacity: 0;
                                            transform: scale(.9);
                                            transition: .5s;
                                            transition-delay: .15s;
                                        }

                                        &:hover img {
                                            transition-delay: .15s;
                                            opacity: 1;
                                            transform: scale(1);
                                            padding-top: 0;
                                        }
                                    }
                                }

                            }

                        }

                        #works {
                            display: grid;
                            grid-template-columns: auto auto auto;
                            gap: 10px;
                            padding: 50px 0;

                            .vizualizer_container {
                                display: block;
                                height: auto;
                                // background-color: red;
                                padding: 0;

                                .vizualizer {
                                    width: 100%;
                                }
                            }


                        }


                    }



                    .side_details {
                        width: 20%;
                        border-left: 1px solid $border-color;
                        // background-color: gray;

                        .details {
                            width: 100%;
                            height: 100vh;
                            // background-color: orangered;
                            position: sticky;
                            top: 0;
                            display: grid;
                            place-items: center;

                            .container {
                                width: 80%;
                                //height: 100%;
                                margin: 0 auto;
                                display: flex;
                                flex-direction: column;
                                padding-top: 30px;
                                // background-color: red;

                                .content {
                                    //height: 50%;

                                    .current_image {
                                        width: 100%;



                                        img {
                                            width: 100%;
                                        }
                                    }

                                    h1 {
                                        width: 100%;
                                        // background-color: green;
                                        font-size: clamp(1rem, 2vw, 2.5rem);
                                    }

                                    .anim_title {
                                        display: none;

                                        :is(h3, p) {
                                            margin: 0;
                                        }

                                        h3 {
                                            font-weight: 500;
                                            margin-top: 10px;
                                        }
                                    }

                                    p {
                                        font-size: .6rem;
                                        line-height: 1.7;
                                    }

                                    .director {
                                        margin-top: 30px;

                                        .directed_img {
                                            width: 10%;

                                            img {
                                                width: 100%;
                                            }


                                        }

                                        p {
                                            margin: 0;
                                        }
                                    }
                                }

                                .quick_navigation {
                                    // background-color: olivedrab;
                                    margin-top: 30px;

                                    .heading {
                                        font-size: .6rem;
                                        border-top: 1px solid $border-color;
                                        padding-top: 10px;
                                    }

                                    .navigation_flexbox {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: flex-end;

                                        .navigation_img {
                                            width: 40%;

                                            img {
                                                width: 100%;
                                            }
                                        }

                                        div {
                                            p {
                                                margin: 0;
                                                font-size: .8rem;
                                            }
                                        }

                                        .navigation_img:nth-child(3) {
                                            width: 15%;

                                            img {
                                                width: 100%;
                                            }
                                        }
                                    }


                                }


                            }
                        }
                    }
                }
            }


        }


    }
}