@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@font-face {
  font-family: gill;
  src: url("GILSANUB.e718a536.TTF");
}

.darkmode {
  background-color: #141519;
}

.darkmode p, .darkmode h1, .darkmode h2, .darkmode h3 {
  color: #fff !important;
}

.darkmode .about_section .about .poka_icon img, .darkmode .portfolio_section .portfolio_container .cards .details .long_arrow img, .darkmode .logo img, .darkmode .link_icon img, .darkmode .mobile_caption h5, .darkmode .pause_button {
  filter: invert();
}

.darkmode .layout .parent .child {
  background-color: #141519;
}

.darkmode .layout .parent .child .play_buttons img {
  filter: invert();
}

.darkmode .gallery_container {
  background-color: #141519;
}

.darkmode .glow img {
  filter: invert();
}

.darkmode .gradient_background .gradient {
  background-image: linear-gradient(to top, #1e2026, #000) !important;
}

body {
  font-optical-sizing: auto;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  overflow: hidden;
}

body [data-barba="container"] {
  position: absolute;
}

body .layout {
  z-index: 1;
  pointer-events: none;
  opacity: 0;
  width: 100%;
  display: none;
  position: absolute;
  top: 0;
}

body .layout .parent {
  width: 100%;
  height: 100dvh;
  position: sticky;
  top: 0;
}

body .layout .parent .child {
  pointer-events: auto;
  background-color: #fff;
  border-top: 1px solid gray;
  border-bottom: 1px solid #d9d9d9;
  place-items: center;
  width: 100%;
  height: 150px;
  display: grid;
  position: absolute;
  bottom: 0;
}

body .layout .parent .child .container {
  width: 95%;
  margin: 0 auto;
}

body .layout .parent .child .container .flexbox {
  display: flex;
}

body .layout .parent .child .container .flexbox .flex1 {
  gap: 10px;
  width: 90%;
  display: flex;
}

body .layout .parent .child .container .flexbox .flex1 .flex {
  place-items: center;
  display: grid;
}

body .layout .parent .child .container .flexbox .flex1 .flex:first-child {
  width: 50%;
}

body .layout .parent .child .container .flexbox .flex1 .flex:first-child .current_image, body .layout .parent .child .container .flexbox .flex1 .flex:first-child .current_image img {
  width: 100%;
}

body .layout .parent .child .container .flexbox .flex1 .flex:nth-child(2) .content {
  width: 90%;
}

body .layout .parent .child .container .flexbox .flex1 .flex:nth-child(2) .content h5 {
  margin: 0;
  font-weight: 500;
}

body .layout .parent .child .container .flexbox .flex1 .flex:nth-child(2) .content .mobile_anim_title h3 {
  margin: 10px 0 0;
  font-size: .9rem;
  font-weight: 700;
  line-height: 1.4;
}

body .layout .parent .child .container .flexbox .flex1 .flex:nth-child(2) .content p {
  color: #5a5a5a;
  font-size: .7rem;
  font-weight: 400;
  line-height: 1.4;
}

body .layout .parent .child .container .flexbox .flex2 {
  place-items: center;
  width: 10%;
  display: grid;
}

body .layout .parent .child .container .flexbox .flex2 .play_buttons, body .layout .parent .child .container .flexbox .flex2 .play_buttons img {
  width: 100%;
}

body nav {
  z-index: 100;
  backdrop-filter: blur(10px);
  background-color: #14132185;
  width: 100%;
  height: fit-content;
  transition: top 1s ease-out;
  position: fixed;
  top: 0;
}

body nav ul {
  border-bottom: 1px solid #4d4d4d;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  display: flex;
}

body nav ul li {
  list-style-type: none;
}

body nav ul li:first-child {
  cursor: pointer;
  border-right: 1px solid #646464;
  padding: 25px 50px;
  position: relative;
}

body nav ul li:first-child .menu {
  pointer-events: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body nav ul li:first-child .menu .bar {
  background-color: #fff;
  width: 40px;
  height: 1px;
  margin: 10px 0;
}

body nav ul li:nth-child(2) {
  place-items: center;
  width: 110px;
  padding: 20px 0;
  display: grid;
}

body nav ul li:nth-child(2) img {
  width: 100%;
}

body nav ul li:nth-child(3) {
  border-left: 1px solid gray;
  display: flex;
}

body nav ul li:nth-child(3) div {
  cursor: pointer;
}

body nav ul li:nth-child(3) div:first-child {
  width: 28px;
  padding: 25px 30px;
}

body nav ul li:nth-child(3) div:first-child img {
  filter: invert();
  width: 100%;
}

body nav ul li:nth-child(3) div:nth-child(2) {
  place-items: center;
  padding: 25px 40px 25px 0;
  display: grid;
}

body nav ul li:nth-child(3) div:nth-child(2) p {
  color: #fff;
  margin: 0;
}

body nav .menu_container {
  color: #fff;
  height: 100svh;
  display: none;
}

body nav .menu_container .container {
  height: inherit;
  width: 90%;
  margin: auto;
}

body nav .menu_container .container .flexbox {
  height: inherit;
  gap: 50px;
  display: flex;
}

body nav .menu_container .container .flexbox .flex1 {
  width: 80%;
}

body nav .menu_container .container .flexbox .flex1 div {
  border-top: 1px solid #dfdfdf82;
  width: 0;
  position: relative;
  overflow: hidden;
}

body nav .menu_container .container .flexbox .flex1 div:first-child {
  border-top: none;
  width: 100%;
  display: flex;
}

body nav .menu_container .container .flexbox .flex1 div h1 {
  pointer-events: auto;
  cursor: pointer;
  margin: 35px 0;
  font-size: clamp(1rem, 4vw, 4.5rem);
  font-weight: 100;
  position: relative;
  top: 200px;
}

body nav .menu_container .container .flexbox .flex1 div h1 a {
  color: #fff;
  text-decoration: none;
}

body nav .menu_container .container .flexbox .flex1 div h1:nth-child(2n) {
  position: absolute;
  top: 200px;
}

body nav .menu_container .container .flexbox .flex1 div:first-child {
  margin-top: 100px;
}

body nav .menu_container .container .flexbox .flex1 div:nth-child(2) {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

body nav .menu_container .container .flexbox .flex1 div:nth-child(2) div {
  margin-top: auto;
}

body nav .menu_container .container .flexbox .flex1 div:nth-child(2) img {
  object-position: center;
  cursor: pointer;
  width: 10%;
  height: 10%;
}

body nav .menu_container .container .flexbox .flex1 div:nth-child(2) .toggle_arrow {
  transform: scale(-1);
}

body nav .menu_container .container .flexbox .flex1 div:nth-child(4) {
  border-bottom: 1px solid #dfdfdf41;
  justify-content: space-between;
  padding-top: 150px;
  display: flex;
}

body nav .menu_container .container .flexbox .flex1 div:nth-child(4) p {
  font-size: .9rem;
  font-weight: 100;
  position: relative;
  top: 200px;
}

body nav .menu_container .container .flexbox .flex2 {
  height: inherit;
  border-left: 1px solid gray;
  place-items: center;
  width: 20%;
  display: grid;
}

body nav .menu_container .container .flexbox .flex2 .content a {
  color: #fff;
}

body nav .menu_container .container .flexbox .flex2 .content a .featured_gallery {
  width: 100%;
  margin-left: 20px;
  position: relative;
}

body nav .menu_container .container .flexbox .flex2 .content a .featured_gallery img {
  width: 100%;
}

body nav .menu_container .container .flexbox .flex2 .content a .featured_gallery .action_button {
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 2%;
  left: 15%;
  transform: translate(-15%, -2%);
}

body nav .menu_container .container .flexbox .flex2 .content a .featured_gallery .action_button span {
  margin-left: 10px;
}

body nav .menu_container .container .flexbox .flex2 .content .nav_location {
  margin-top: 40px;
}

body nav .menu_container .container .flexbox .flex2 .content .nav_location ol {
  list-style-type: none;
}

body nav .menu_container .container .flexbox .flex2 .content .nav_location ol li {
  padding-top: 5px;
  font-size: .7rem;
  font-weight: 100;
}

body nav .menu_container .container .flexbox .flex2 .content .nav_location ol li:first-child {
  font-size: 1rem;
  font-weight: 300;
}

body nav .active {
  display: block;
}

body .add_nav {
  transition: all 2.5s ease-out;
  top: -100%;
}

body .iframe_container {
  z-index: 102;
  background-color: #000;
  place-items: center;
  width: 80%;
  height: 100svh;
  transition: all .3s;
  display: none;
  position: fixed;
  top: 0;
}

body .iframe_container .close {
  z-index: 9999;
  cursor: pointer;
  color: #fff;
  background-color: #000;
  padding: 10px 20px;
  position: absolute;
  top: 12%;
  right: 2%;
  transform: translate(-2%, -12%);
}

body .iframe_container iframe {
  width: 100%;
  height: 100%;
}

body .page_transition {
  z-index: 1000;
  pointer-events: none;
  width: 100%;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  overflow: hidden;
}

body .page_transition > div {
  background-color: #ededed;
  justify-content: center;
  align-items: center;
  width: 25%;
  height: 100svh;
  display: none;
  transform: translateY(100%);
}

body .page_transition > div:nth-child(2) img {
  width: 30px;
}

body .page_transition > div img {
  opacity: .3;
  width: 25px;
}

body .loading_screen {
  z-index: 9999;
  background-color: #fff;
  width: 100%;
  height: 100dvh;
  display: none;
  position: absolute;
  top: 0;
}

body .loading_screen .loading_logo {
  width: inherit;
  height: inherit;
  place-items: center;
  display: grid;
}

body .loading_screen .loading_logo video {
  width: 85%;
  -webkit-mask-image: url("loading-logo@2x.d6d44fbf.png");
  mask-image: url("loading-logo@2x.d6d44fbf.png");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 90%;
  mask-size: 90%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

body .gallery_container {
  z-index: 100;
  background-color: #fff;
  place-items: center;
  width: 80%;
  height: 100svh;
  transition: all .3s;
  display: none;
  position: fixed;
  top: 0;
}

body .gallery_container .close_gallery {
  z-index: 9999;
  cursor: pointer;
  color: #fff;
  background-color: #000;
  padding: 10px 20px;
  position: absolute;
  top: 12%;
  right: 2%;
  transform: translate(-2%, -12%);
}

body .gallery_container .arrow_holder {
  justify-content: space-between;
  width: 90%;
  display: flex;
}

body .gallery_container .arrow_holder img {
  cursor: pointer;
}

body .gallery_container .arrow_holder img:nth-child(2) {
  transform: scale(-1);
}

body .gallery_container .picture_holder {
  place-items: center;
  width: 70%;
  display: grid;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

body .gallery_container .picture_holder img {
  width: inherit;
  height: inherit;
  object-fit: contain;
}

body section .parent_container .banner {
  width: 100%;
  height: 100svh;
  position: relative;
  top: 0;
  overflow: hidden;
}

body section .parent_container .banner .banner_trey {
  height: inherit;
  width: 200%;
  transition: all 1.5s cubic-bezier(.075, .82, .165, 1);
  display: flex;
  position: absolute;
  left: 0;
  overflow: hidden;
}

body section .parent_container .banner .banner_trey .video_holder {
  height: inherit;
  width: 100%;
}

body section .parent_container .banner .banner_trey .video_holder video {
  height: inherit;
  object-fit: cover;
  width: 50%;
  position: absolute;
  top: 0;
}

body section .parent_container .banner .banner_content {
  color: #fff;
  text-align: center;
  border-top: 1px solid #464646;
  justify-content: space-between;
  align-items: flex-end;
  width: 95%;
  margin-bottom: 50px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

body section .parent_container .banner .banner_content .banner_mute {
  cursor: pointer;
}

body section .parent_container .banner .banner_content div:nth-child(2) {
  width: 450px;
  line-height: 1.8;
}

body section .parent_container .banner .banner_content div:nth-child(2) h1 {
  font-family: gill;
  font-size: clamp(1rem, 2vw, 2rem);
}

body section .parent_container .banner .banner_content div:nth-child(2) p {
  margin-top: 5px;
  margin-bottom: 0;
  font-size: .9rem;
}

body section .parent_container .banner .banner_content div:nth-child(3) {
  cursor: pointer;
  text-decoration: underline;
  transition: all .3s;
}

body section .parent_container .banner .banner_content div:nth-child(3):hover {
  letter-spacing: .5px;
  font-weight: 700;
  transform: scale(1.05);
}

body section .parent_container .women_community {
  align-items: flex-end;
  width: 100%;
  height: 650px;
  display: flex;
}

body section .parent_container .women_community .background {
  width: inherit;
  background-image: linear-gradient(to top, #101621, #5f636a);
  height: 100%;
  position: relative;
}

body section .parent_container .women_community .background .woman_img {
  width: 43%;
  position: absolute;
  bottom: 0;
  left: 10%;
  transform: translateX(-10%);
}

body section .parent_container .women_community .background .woman_img img {
  width: 100%;
}

body section .parent_container .women_community .background .content_container {
  height: inherit;
  justify-content: flex-end;
  align-items: center;
  width: 70%;
  margin: 0 auto;
  display: flex;
}

body section .parent_container .women_community .background .content_container .content {
  width: 50%;
  font-family: Poppins;
}

body section .parent_container .women_community .background .content_container .content h1 {
  color: #fde597;
  margin: 0;
  font-size: clamp(1rem, 4vw, 3.5rem);
  font-weight: 500;
}

body section .parent_container .women_community .background .content_container .content h5 {
  color: #aaa;
  font-weight: 300;
}

body section .parent_container .women_community .background .content_container .content p {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 200;
  line-height: 1.7;
}

body section .parent_container .women_community .background .content_container .content .buttons_container {
  margin-top: 50px;
}

body section .parent_container .women_community .background .content_container .content .buttons_container button {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 15px 40px;
  font-size: 1.1rem;
}

body section .parent_container .women_community .background .content_container .content .buttons_container button:nth-child(2) {
  color: #fff;
  background-color: #0000;
  margin-left: 15px;
}

body section .parent_container .main {
  margin-top: 10px;
}

body section .parent_container .main .latest_container {
  margin-top: 100px;
}

body section .parent_container .main .latest_container .last_night {
  width: 90%;
  margin: 0 auto;
  position: relative;
}

body section .parent_container .main .latest_container .last_night .flexbox {
  gap: 70px;
  display: flex;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details {
  width: max(300px, 45%);
  position: relative;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_flex {
  align-items: center;
  display: flex;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_flex .svg {
  width: 70px;
  height: 100px;
  position: relative;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_flex .svg svg {
  fill: #0000;
  stroke: red;
  stroke-dasharray: 251;
  stroke-dashoffset: 251px;
  transition: all 10s linear;
  position: absolute;
  left: -20px;
  transform: scale(.5);
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_flex .svg .pause_button {
  cursor: pointer;
  width: 15px;
  position: absolute;
  top: 52%;
  left: 40%;
  transform: translate(-40%, -52%);
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_flex .svg .pause_button img {
  width: 100%;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details h1 {
  color: #111123;
  opacity: 0;
  font-family: Poppins;
  font-size: clamp(1rem, 2vw, 1.4rem);
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description {
  opacity: 0;
  width: 100%;
  padding-top: 100px;
  position: absolute;
  bottom: 10%;
  overflow: hidden;
  transform: translateY(-15%);
  container-type: inline-size;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey {
  width: 200%;
  display: flex;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description {
  width: 50%;
  display: flex;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description p {
  font-size: clamp(.7rem, .8vw, 1rem);
  width: auto !important;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description:first-child {
  align-items: flex-end;
  display: flex;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description .mobile_caption {
  display: none;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description .content_trey .content_description .mobile_caption h1 {
  color: #000;
  opacity: 1;
  font-size: 2rem;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .latest_description p {
  color: #555;
  width: max(400px, 50%);
  font-size: clamp(.3rem, 1vw, 1rem);
  font-weight: 500;
  line-height: 1.9;
}

body section .parent_container .main .latest_container .last_night .flexbox .latest_project_details .visible {
  opacity: 1;
  padding-top: 0;
  transition: opacity .5s, padding-top .5s;
}

body section .parent_container .main .latest_container .last_night .flexbox .poster1 {
  opacity: 0;
  width: 550px;
  transition: opacity .5s;
  position: relative;
  overflow: hidden;
}

body section .parent_container .main .latest_container .last_night .flexbox .poster1 .flex2_trey {
  width: 200%;
  display: flex;
}

body section .parent_container .main .latest_container .last_night .flexbox .poster1 .flex2_trey img {
  width: 50%;
}

body section .parent_container .main .latest_container .last_night .flexbox .poster1 .flex2_trey .new_project {
  width: 100%;
}

body section .parent_container .main .latest_container .last_night .flexbox .poster1 .flex2_trey .new_project video {
  width: 100%;
  height: auto;
}

body section .parent_container .main .latest_container .last_night .flexbox .content {
  width: 520px;
  position: relative;
}

body section .parent_container .main .latest_container .last_night .flexbox .content .last_night_caption {
  opacity: 0;
  padding-top: 50px;
  transition: opacity .5s, padding-top 1s ease-out;
  overflow: hidden;
}

body section .parent_container .main .latest_container .last_night .flexbox .content .last_night_caption .last_night_caption_trey {
  width: 200%;
  display: flex;
}

body section .parent_container .main .latest_container .last_night .flexbox .content .last_night_caption .last_night_caption_trey .captions {
  width: 100%;
}

body section .parent_container .main .latest_container .last_night .flexbox .content .last_night_caption .last_night_caption_trey .captions h1 {
  font-size: clamp(1rem, 4vw, 5.5rem);
}

body section .parent_container .main .latest_container .last_night .flexbox .content .last_night_caption .last_night_caption_trey .captions p {
  font-size: clamp(.9rem, 1vw, 1.1rem);
  font-weight: 500;
}

body section .parent_container .main .latest_container .last_night .flexbox .content .last_night_caption h1 {
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#ff3a12, #b40000);
  -webkit-background-clip: text;
  width: 100%;
  margin: 0;
  font-family: gill;
  font-size: clamp(1rem, 6.3vw, 6.2rem);
  line-height: 1;
}

body section .parent_container .main .latest_container .last_night .flexbox .content .last_night_caption p {
  color: #363636;
  width: max(450px, 50%);
  font-size: .9rem;
  line-height: 1.6;
}

body section .parent_container .main .latest_container .last_night .flexbox .content .poster2 {
  width: max(150px, 40%);
  position: absolute;
  bottom: -30px;
  overflow: hidden;
}

body section .parent_container .main .latest_container .last_night .flexbox .content .poster2 .poster2_trey {
  align-items: center;
  width: 200%;
  display: flex;
}

body section .parent_container .main .latest_container .last_night .flexbox .content .poster2 .poster2_trey .supporting_images {
  width: 50%;
}

body section .parent_container .main .latest_container .last_night .flexbox .content .poster2 .poster2_trey .supporting_images img {
  width: 90%;
}

body section .parent_container .main .latest_container .latest_project {
  z-index: -1;
  width: 100%;
  position: relative;
  top: -80px;
}

body section .parent_container .main .latest_container .latest_project .last_night_details {
  width: inherit;
  border-top: 1px solid #bebebe;
  border-bottom: 1px solid #bebebe;
  justify-content: space-between;
  display: flex;
}

body section .parent_container .main .latest_container .latest_project .last_night_details p {
  margin: 0;
  padding: 55px 100px;
}

body section .parent_container .main .latest_container .latest_project .last_night_details p:nth-child(2) {
  border-left: 1px solid gray;
  font-size: clamp(1rem, 1.5vw, 1.1rem);
  font-weight: 500;
  text-decoration: underline;
}

body section .parent_container .portfolio_section {
  display: grid;
}

body section .parent_container .portfolio_section .caption {
  text-align: center;
}

body section .parent_container .portfolio_section .caption h1 {
  color: #111123;
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 0;
  font-family: gill;
  font-size: clamp(1rem, 11vw, 13rem);
  font-weight: 900;
}

body section .parent_container .portfolio_section .caption h1 span {
  opacity: 0;
  position: relative;
  top: 50px;
}

body section .parent_container .portfolio_section .portfolio_container {
  width: 90%;
  height: max(100em, 200dvh);
  margin: 100px auto 0;
  position: relative;
}

body section .parent_container .portfolio_section .portfolio_container h1 {
  width: 40%;
  font-family: poppins;
  font-size: clamp(1px, 2.5vw, 4.5rem);
  line-height: 1.5;
  text-decoration: underline;
}

body section .parent_container .portfolio_section .portfolio_container .cards {
  width: 30%;
}

body section .parent_container .portfolio_section .portfolio_container .cards .trigger {
  z-index: 10;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

body section .parent_container .portfolio_section .portfolio_container .cards .cover {
  padding-bottom: 30px;
  position: relative;
  overflow: hidden;
}

body section .parent_container .portfolio_section .portfolio_container .cards .cover img {
  border: 1px solid #5d5d5d;
  width: 100%;
}

body section .parent_container .portfolio_section .portfolio_container .cards .cover video {
  opacity: 0;
  visibility: hidden;
  transition: all .5s;
  display: none;
  position: absolute;
  top: 0;
}

body section .parent_container .portfolio_section .portfolio_container .cards .details {
  border-top: 1px solid #c8c8c8;
  justify-content: space-between;
  align-items: center;
  padding-top: 5px;
  display: flex;
}

body section .parent_container .portfolio_section .portfolio_container .cards .details h3 {
  color: #111123;
  text-decoration: underline;
}

body section .parent_container .portfolio_section .portfolio_container a .cards {
  width: 470px;
  height: 370px;
  margin-top: 50px;
  transition: all .5s;
  position: absolute;
  top: 0;
  right: 3%;
  transform: translateX(-3%);
}

body section .parent_container .portfolio_section .portfolio_container a .cards img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

body section .parent_container .portfolio_section .portfolio_container a .cards a {
  border: 10px solid red;
  position: relative;
}

body section .parent_container .portfolio_section .portfolio_container a .cards .cover, body section .parent_container .portfolio_section .portfolio_container a .cards .details {
  transition: all .5s;
}

body section .parent_container .portfolio_section .portfolio_container a .cards video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

body section .parent_container .portfolio_section .portfolio_container a:nth-child(3) .cards {
  transform: translateY(-3%, -23%);
  width: 450px;
  position: absolute;
  top: 23%;
  left: 3%;
}

body section .parent_container .portfolio_section .portfolio_container a:nth-child(3) .cards .cover, body section .parent_container .portfolio_section .portfolio_container a:nth-child(3) .cards .details {
  transition: all .5s;
}

body section .parent_container .portfolio_section .portfolio_container a:nth-child(3) .cards .cover {
  width: 450px;
  height: 350px;
}

body section .parent_container .portfolio_section .portfolio_container a:nth-child(3) .cards .cover img, body section .parent_container .portfolio_section .portfolio_container a:nth-child(3) .cards video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) {
  position: absolute;
  top: 60%;
  right: 5%;
  transform: translate(-5%, -60%);
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) .cover {
  width: 470px;
  height: 370px;
  margin-top: 50px;
  transition: all .5s;
  overflow: hidden;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) .cover img {
  object-fit: cover;
  border: 1px solid #5d5d5d;
  width: 100%;
  height: 100%;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) .cover video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) .details {
  transition: all .5s;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) .flex1 {
  border-top: 1px solid #c8c8c8;
  margin-top: 180px;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) .flex1 p {
  padding-top: 30px;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) p {
  margin: 0;
  font-size: .9rem;
  line-height: 2;
  transition: all .5s;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(4) .flex2 p {
  color: #4d4d4d;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(5) {
  transform: translateY(-7%, -65%);
  cursor: pointer;
  width: 450px;
  height: 350px;
  position: absolute;
  top: 65%;
  left: 7%;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(5) img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(5) .cover, body section .parent_container .portfolio_section .portfolio_container .cards:nth-child(5) .details {
  transition: all .5s;
}

body section .parent_container .reels_section {
  color: #fff;
  background-color: #111123;
  width: 100%;
  display: none;
}

body section .parent_container .reels_section .reels_container {
  align-items: flex-end;
  width: 80%;
  height: 80vh;
  margin: 0 auto;
  padding: 100px 0;
  display: flex;
}

body section .parent_container .reels_section .reels_container .flex1 {
  height: inherit;
  width: 55%;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details {
  height: inherit;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  display: flex;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading {
  opacity: 0;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading h1 {
  font-family: poppins;
  font-size: clamp(1rem, 3vw, 2.1rem);
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading p {
  font-size: .9rem;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading .socials {
  align-items: center;
  margin-top: 25px;
  display: flex;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading .socials .social1 {
  width: 10%;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading .socials .social1 img {
  width: 100%;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading .socials .social2, body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading .socials .social3 {
  width: 10%;
  padding-left: 10px;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading .socials .social2 img, body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading .socials .social3 img {
  object-position: center;
  width: 100%;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading .socials .social3 {
  width: 8%;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .reel_heading .socials .social3 img {
  object-position: center;
  width: 100%;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .details {
  opacity: 0;
}

body section .parent_container .reels_section .reels_container .flex1 .reel_details .details p {
  font-size: .9rem;
  line-height: 1.9;
}

body section .parent_container .reels_section .reels_container .flex2 {
  opacity: 0;
  border: 1px solid #fff;
  align-items: flex-end;
  width: 35%;
  height: 80vh;
  display: flex;
  overflow: hidden;
}

body section .parent_container .reels_section .reels_container .flex2 .video_reel {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey {
  height: 120vh;
  transition: all 1s cubic-bezier(.075, .82, .165, 1);
}

body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey .overlay {
  display: none;
}

body section .parent_container .reels_section .reels_container .flex2 .video_reel .video_trey video {
  object-fit: cover;
  width: 100%;
  height: 80vh;
}

body section .parent_container .reels_section .reels_container .flex3 {
  opacity: 0;
  align-items: flex-end;
  padding-left: 100px;
  display: flex;
}

body section .parent_container .reels_section .reels_container .flex3 .reel_buttons {
  flex-direction: column;
  justify-content: space-evenly;
  height: 30vh;
  display: flex;
}

body section .parent_container .reels_section .reels_container .flex3 .reel_buttons .icon {
  cursor: pointer;
}

body section .parent_container .reels_section .reels_container .flex3 .reel_buttons .icon:first-child {
  width: 40px;
}

body section .parent_container .reels_section .reels_container .flex3 .reel_buttons .icon:first-child img {
  width: 100%;
  transform: rotate(-90deg);
}

body section .parent_container .reels_section .reels_container .flex3 .reel_buttons .icon:nth-child(2) {
  width: 40px;
}

body section .parent_container .reels_section .reels_container .flex3 .reel_buttons .icon:nth-child(2) img {
  width: 100%;
  transform: rotate(90deg);
}

body section .parent_container .reels_section .reels_container .flex3 .reel_buttons .icon:nth-child(3) {
  width: 40px;
}

body section .parent_container .reels_section .reels_container .flex3 .reel_buttons .icon:nth-child(3) img {
  width: 100%;
}

body section .parent_container .reels_section .reels_container .flex3 .reel_buttons .icon:nth-child(4) {
  width: 40px;
}

body section .parent_container .reels_section .reels_container .flex3 .reel_buttons .icon:nth-child(4) img {
  width: 100%;
}

body section .parent_container .about_section {
  background-size: 300px;
  background-attachment: fixed;
  place-items: center;
  width: 100%;
  padding: 130px 0;
  display: grid;
}

body section .parent_container .about_section .about {
  text-align: center;
  opacity: 0;
  width: 40%;
  padding-top: 100px;
  transition: opacity .5s, padding-top 1s ease-out;
}

body section .parent_container .about_section .about h1 {
  margin: 0;
  font-family: gill;
  font-size: clamp(1rem, 6vw, 7rem);
}

body section .parent_container .about_section .about p {
  font-size: clamp(1rem, 1.4vw, 1.3rem);
  line-height: 1.5;
}

body section .parent_container .about_section .about p:nth-child(4) {
  text-decoration: underline;
}

body section .parent_container .line {
  border: 1px solid #c8c8c8a4;
  width: 100%;
}

body section .parent_container footer .contact {
  justify-content: space-between;
  display: flex;
}

body section .parent_container footer .contact .flex {
  align-items: center;
  gap: 20px;
  width: 50%;
  display: flex;
}

body section .parent_container footer .contact .flex .contact_img {
  opacity: 0;
  border-right: 1px solid gray;
  width: 165px;
  overflow: hidden;
}

body section .parent_container footer .contact .flex .contact_img img {
  width: 100%;
  transform: scale(1.06);
}

body section .parent_container footer .contact .flex .challenge_img {
  opacity: 0;
  border-right: 1px solid gray;
  width: 200px;
  overflow: hidden;
}

body section .parent_container footer .contact .flex .challenge_img img {
  width: 100%;
  transform: scale(1.03);
}

body section .parent_container footer .contact .flex .content {
  opacity: 0;
  flex-grow: 1;
  justify-content: center;
  width: fit-content;
  display: flex;
}

body section .parent_container footer .contact .flex h1 {
  cursor: pointer;
  width: 90%;
  margin: 0;
  font-family: poppins;
  font-size: clamp(1rem, 2vw, 1.5rem);
  line-height: 2;
  text-decoration: underline;
}

body section .parent_container footer .contact .flex .challenge {
  width: 60%;
}

body section .parent_container footer .contact .flex h3 {
  margin: 0;
}

body section .parent_container footer .contact .line_2 {
  border: 1px solid #c8c8c8a4;
}

body section .parent_container footer .footer {
  padding: 100px 0;
}

body section .parent_container footer .footer .footer_content {
  justify-content: space-around;
  display: flex;
}

body section .parent_container footer .footer .footer_content .location {
  margin-top: 20px;
}

body section .parent_container footer .footer .footer_content .location .location_container {
  align-items: flex-end;
  display: flex;
}

body section .parent_container footer .footer .footer_content .location .location_container h2 {
  margin: 0;
  text-decoration: underline;
}

body section .parent_container footer .footer .footer_content .location .location_container div {
  width: 15px;
  padding-left: 10px;
}

body section .parent_container footer .footer .footer_content .location .location_container div img {
  width: 100%;
}

body section .parent_container footer .footer .footer_content .social_media ul {
  list-style: none;
}

body section .parent_container footer .footer .footer_content .social_media ul li {
  align-items: flex-end;
  margin: 20px 0;
  display: flex;
}

body section .parent_container footer .footer .footer_content .social_media ul li a {
  color: #000;
}

body section .parent_container footer .footer .footer_content .social_media ul li a h2 {
  margin: 0 10px 0 0;
}

body section .parent_container footer .footer .footer_content .social_media ul li div {
  width: 15px;
}

body section .parent_container footer .footer .footer_content .social_media ul li div img {
  width: 100%;
}

body section .parent_container .work_section {
  border-bottom: 1px solid #c8c8c8a4;
  width: 100%;
  position: relative;
}

body section .parent_container .work_section .gradient_background {
  width: 80%;
  position: absolute;
}

body section .parent_container .work_section .gradient_background .gradient {
  background: linear-gradient(to top, #d3d3e7, #0000);
  width: 100%;
  height: 100svh;
  position: sticky;
  top: 0;
}

body section .parent_container .work_section .works_flexbox {
  height: 100%;
  display: flex;
}

body section .parent_container .work_section .works_flexbox .works_container {
  width: 80%;
  height: 100%;
  position: relative;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container {
  justify-content: space-around;
  height: 110vh;
  padding: 200px 0 0;
  display: flex;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer {
  transform: rotate3d(0);
  cursor: pointer;
  width: 40%;
  transition: transform .3s ease-out;
  position: relative;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer .viz_img_container {
  width: 100%;
  height: max(275px, 38vh);
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer .viz_img_container img {
  border: 1px solid gray;
  width: 100%;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer .viz_img_container .glow {
  height: inherit;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer .gallery_img_container {
  width: 100%;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer .gallery_img_container img {
  border: 1px solid gray;
  width: 100%;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer .gallery_img_container img .glow {
  height: inherit;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer:hover {
  transition: all .15s;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer:first-child {
  cursor: pointer;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer:first-child .glow {
  cursor: pointer;
  background-image: radial-gradient(circle at 50% -20%, #fff2, #0000000f);
  place-items: center;
  width: 100%;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer:first-child .glow img {
  opacity: 0;
  border: none;
  width: 30%;
  padding-top: 20px;
  transition: all .5s .15s;
  transform: scale(.9);
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer:first-child .glow:hover img {
  opacity: 1;
  padding-top: 0;
  transition-delay: .15s;
  transform: scale(1);
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer:nth-child(2) {
  cursor: pointer;
  align-items: flex-end;
  height: 85%;
  display: flex;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer:nth-child(2) .glow {
  background-image: radial-gradient(circle at 50% -20%, #fff2, #0000000f);
  place-items: center;
  width: 100%;
  display: grid;
  position: absolute;
  bottom: 0;
  left: 0;
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer:nth-child(2) .glow img {
  opacity: 0;
  border: none;
  width: 30%;
  padding-top: 20px;
  transition: all .5s .15s;
  transform: scale(.9);
}

body section .parent_container .work_section .works_flexbox .works_container .works .vizualizer_container .vizualizer:nth-child(2) .glow:hover img {
  opacity: 1;
  padding-top: 0;
  transition-delay: .15s;
  transform: scale(1);
}

body section .parent_container .work_section .works_flexbox .works_container #works {
  grid-template-columns: auto auto auto;
  gap: 10px;
  padding: 50px 0;
  display: grid;
}

body section .parent_container .work_section .works_flexbox .works_container #works .vizualizer_container {
  height: auto;
  padding: 0;
  display: block;
}

body section .parent_container .work_section .works_flexbox .works_container #works .vizualizer_container .vizualizer {
  width: 100%;
}

body section .parent_container .work_section .works_flexbox .side_details {
  border-left: 1px solid #c8c8c8a4;
  width: 20%;
}

body section .parent_container .work_section .works_flexbox .side_details .details {
  place-items: center;
  width: 100%;
  height: 100vh;
  display: grid;
  position: sticky;
  top: 0;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container {
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
  padding-top: 30px;
  display: flex;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .content .current_image, body section .parent_container .work_section .works_flexbox .side_details .details .container .content .current_image img {
  width: 100%;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .content h1 {
  width: 100%;
  font-size: clamp(1rem, 2vw, 2.5rem);
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .content .anim_title {
  display: none;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .content .anim_title :is(h3, p) {
  margin: 0;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .content .anim_title h3 {
  margin-top: 10px;
  font-weight: 500;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .content p {
  font-size: .6rem;
  line-height: 1.7;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .content .director {
  margin-top: 30px;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .content .director .directed_img {
  width: 10%;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .content .director .directed_img img {
  width: 100%;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .content .director p {
  margin: 0;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .quick_navigation {
  margin-top: 30px;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .quick_navigation .heading {
  border-top: 1px solid #c8c8c8a4;
  padding-top: 10px;
  font-size: .6rem;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .quick_navigation .navigation_flexbox {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .quick_navigation .navigation_flexbox .navigation_img {
  width: 40%;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .quick_navigation .navigation_flexbox .navigation_img img {
  width: 100%;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .quick_navigation .navigation_flexbox div p {
  margin: 0;
  font-size: .8rem;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .quick_navigation .navigation_flexbox .navigation_img:nth-child(3) {
  width: 15%;
}

body section .parent_container .work_section .works_flexbox .side_details .details .container .quick_navigation .navigation_flexbox .navigation_img:nth-child(3) img {
  width: 100%;
}
/*# sourceMappingURL=index.b88d2eea.css.map */
